export async function dateFormat (date, time = false) {
  if (date) {
    const format = !time ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'
    const { default: moment } = await import('moment-mini')
    return moment(date).format(format)
  }
  return ''
}

export async function getStructEstablishment (establishment, appUrl, merchantName) {
  const { default: moment } = await import('moment-mini')

  return `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "${establishment.company_name}",
    "startDate": "${moment(establishment.date_creation)
    .format('YYYY-MM-DD')}",
    "url": "${appUrl}/entreprise/${establishment.url}",
    "numberOfEmployees": "${establishment.staff_description}",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "${establishment.way_type ?? ''}${establishment.way}",
      "postalCode": "${establishment.postal_code}",
      "addressLocality": "${establishment.city}"
    },
    "publisher": {
      "@type": "Organization",
      "name": "${merchantName || 'DocuBiz'} : kbis, Tva intra, Siren/Siret, bilans - L'info légale en illimité!",
      "description": "Consultez les informations légales et téléchargez en illimité les documents sur les entreprises : bilans, annonces, statuts, kbis, situation SIRENE…",
      "logo": "${appUrl}/logo.svg",
      "image": "${appUrl}/social.jpg"
    }
  }`
}

export function validateEmail (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(email.toLowerCase())
}

export function getCookie (name, cookie = document.cookie) {
  const matches = cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
  ))
  return matches ? decodeURIComponent(matches[1]) : null
}

export function setCookie (name, value, minutes) {
  let expires = ''
  if (minutes) {
    const date = new Date()
    date.setTime(date.getTime() + (minutes * 60 * 1000))
    expires = `; expires=${date.toUTCString()}`
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

export function search (source, searchString, unidecode, tag = 'b') {
  const position = unidecode(source.toLowerCase().trim())
    .indexOf(unidecode(searchString.toLowerCase().trim()))
  if (position !== -1) {
    const start = `${source.slice(0, position)}`
    const bold = `<${tag}>${source.slice(position, position + searchString.length)}</${tag}>`
    const end = `${source.slice(position + searchString.length)}`
    return `${start}${bold}${end}`
  }
  return source
}

export function searchWords (source, searchString, unidecode, tag = 'mark') {
  let src = source
  const words = unidecode(searchString.toLowerCase().trim()).split(/\b/)

  function act (source, searchString) {
    const _source = unidecode(source.toLowerCase().trim())

    const position = _source.indexOf(searchString)
    if (position !== -1) {
      const start = `${source.slice(0, position)}`
      const bold = `<${tag}>${source.slice(position, position + searchString.length)}</${tag}>`
      const end = `${source.slice(position + searchString.length)}`
      return `${start}${bold}${end}`
    }
    return source
  }

  for (const word of words) {
    src = act(src, word)
  }

  return src
}

export function ucFirst (str) {
  if (!str) return str

  return str[0].toUpperCase() + str.slice(1).toLowerCase()
}

export function scrollToId (_id, tryCount = 0, maxTries = 25) {
  if (tryCount === maxTries) return
  setTimeout(() => {
    const el = document.getElementById(_id)
    if (!el) scrollToId(_id, tryCount + 1, maxTries)
    else {
      setTimeout(() => {
        el.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }, 100)
    }
  }, 100)
}
